<template>
  <section class="">
    <div class="container">
      <div
        class="box box-warning"
        v-if="$can('view', this.$route.path) || $can('view', this)"
      >
        <div class="nav-tabs-custom">
          <div>
            <iframe
              v-bind:src="src"
              style="width:100%;min-height:90vh;padding:0;margin:0;border:0;"
            />
          </div>
        </div>
      </div>
      <InfoBox v-else preset="unauthorized" />
    </div>
  </section>
</template>

<script>
import ComponentNotAvailable from "@/components/component-not-available.vue";
import InfoBox from "@/components/info-box.vue";

export default {
  name: "DashboardPageContainer",
  components: {
    InfoBox
  },
  props: {
    filename: {
      type: String,
      required: true
    }
  },
  computed: {
    src: function() {
      var dashboard = this.$http.options.dashboard;
      var filename = this.filename + ".html";
      return `${dashboard}/pages/${filename}`;
    }
  }
};
</script>

<style scoped>
.nav-tabs-custom {
  margin-bottom: 0;
}
.box {
  margin-bottom: 0;
}
</style>
